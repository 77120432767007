<template>
  <div>
    <v-btn @click="previousStep" text>
      <v-icon left>
        mdi-arrow-left
      </v-icon>
      {{ message }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "BtnPreviousStep",
  methods: {
    previousStep() {
      this.$emit("previousStep");
    }
  },
  props: {
    message: String
  }
};
</script>
